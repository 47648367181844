import { Amplify, Auth } from 'aws-amplify'
import React, { useEffect, useState, useMemo, createContext, useContext } from 'react'
import { AwsConfigAuth } from '../config/auth'
Amplify.configure({ Auth: AwsConfigAuth() })

const authContext = createContext()

export function useAuth() {
	return useContext(authContext)
}

export const ProvideAuth = ({ children }) => {
	const auth = useProvideAuth()
	return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export function useProvideAuth() {
	const [isSignedIn, setisSignedIn] = useState(false)
	const [user, setUser] = useState({})
	const [session, setSession] = useState()

	const auth = useMemo(() => {
		Auth.configure(AwsConfigAuth())
		return Auth
	}, [])
	// const session = useMemo(async () => {
	// 	return await auth.currentSession()
	// }, [])

	useEffect(() => {
		async function fetchSession() {
			return await auth.currentSession()
		}
		setSession(fetchSession())
	}, [isSignedIn])

	useEffect(() => {
		auth.currentAuthenticatedUser()
			.then((user) => {
				setUser(user)
				setisSignedIn(true)
			})
			.catch((error) => {
				console.error(error)
			})
	}, [])

	// useEffect(() => {
	// 	console.log('session: ', session)
	// }, [session])

	async function signIn(username, password) {
		try {
			await auth.signIn(username, password)
			setisSignedIn(true)
			return true
		} catch (error) {
			console.error(error)
			return false
		}
	}

	async function signOut() {
		try {
			await auth.signOut()
			setisSignedIn(false)
			return true
		} catch (error) {
			console.error(error)
			return false
		}
	}

	async function globalSignOut() {
		try {
			await auth.signOut({ global: true })
			setisSignedIn(false)
			return true
		} catch (error) {
			console.error(err)
			return false
		}
	}

	// async function getAuthSessionAndRefreshWhenRefreshTokenIsValid() {
	// 	let session = undefined
	// 	try {
	// 		session = await auth.currentSession()
	// 	} catch (error) {
	// 		console.error('Could not retrieve Cognito auth session')
	// 		throw error
	// 	}

	// 	if (!session) {
	// 		throw new Error('No valid session available')
	// 	}

	// 	return session
	// }

	// async function isSessionActive() {
	// 	try {
	// 		const session = await getAuthSessionAndRefreshWhenRefreshTokenIsValid()
	// 		return session.isValid()
	// 	} catch (error) {
	// 		console.error('Could not get Cognito auth session.')
	// 		return false
	// 	}
	// }

	// async function getBearerAuthHeader() {
	// 	try {
	// 		const session = await getAuthSessionAndRefreshWhenRefreshTokenIsValid()
	// 		console.log(session.getAccessToken().getJwtToken())
	// 		return 'Bearer ' + session.getAccessToken().getJwtToken()
	// 	} catch (error) {
	// 		console.error('ID Token does not exist. Unknown User.')
	// 		await logout() //make sure user is logged out
	// 		return undefined
	// 	}
	// }
	//UNTESTED vvvvv

	// change password
	// Auth.currentAuthenticatedUser()
	// 	.then((user) => {
	// 		return Auth.changePassword(user, 'oldPassword', 'newPassword')
	// 	})
	// 	.then((data) => console.log(data))
	// 	.catch((err) => console.log(err))

	// // Forgot password
	// // Send confirmation code to user's email
	// Auth.forgotPassword(username)
	// 	.then((data) => console.log(data))
	// 	.catch((err) => console.log(err))

	// // Collect confirmation code and new password, then
	// Auth.forgotPasswordSubmit(username, code, new_password)
	// 	.then((data) => console.log(data))
	// 	.catch((err) => console.log(err))

	//UNTESTED ^^^^
	return {
		user,
		isSignedIn,
		session,
		signIn,
		signOut,
		globalSignOut,
		// getAuthSessionAndRefreshWhenRefreshTokenIsValid,
		// isSessionActive,
		// getBearerAuthHeader,
	}
}
