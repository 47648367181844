import React, { Component, Suspense } from 'react'
import { Route, Routes, BrowserRouter, Outlet } from 'react-router-dom'
import { useLocation, useNavigate, useParams, Navigate } from 'react-router-dom'
// import Authentication from './Authentication'
import { useAuth } from './hooks/useAuth'

import './scss/style.scss'
// Containers
const DefaultLayout = withRouter(React.lazy(() => import('./layout/DefaultLayout')))

// Pages
const Login = withRouter(React.lazy(() => import('./views/pages/login/Login')))
const Register = withRouter(React.lazy(() => import('./views/pages/register/Register')))
const Page404 = withRouter(React.lazy(() => import('./views/pages/page404/Page404')))
const Page500 = withRouter(React.lazy(() => import('./views/pages/page500/Page500')))

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
)

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation()
		let navigate = useNavigate()
		let params = useParams()
		return <Component {...props} router={{ location, navigate, params }} />
	}

	return ComponentWithRouterProp
}

/* 
	11598Pix!
*/

function PrivateRoutes() {
	const { session } = useAuth()

	/*
		this actually doesn't work as you'd think
		session relies on a promise from useAuth where it checks if a session
		is currently active. regardless a promise is here either rejected (false no session) or fulfilled 
		(true a session is active someone is has logged in)
		so the work around is in Login.js where it won't render its page if there is a session active and will
		navigate to dashboard
	*/
	//TO DO have this use the resolved promise...
	return session ? <Outlet /> : <Navigate to="/login" />
}

// const PrivateRoutes = withRouter(PrivateRoute)

class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<Suspense fallback={loading}>
					<Routes>
						<Route path="/" element={<PrivateRoutes />}>
							<Route path="*" exact element={<DefaultLayout />} />
						</Route>
						<Route exact path="/login" name="Login Page" element={<Login />} />
						<Route exact path="/register" name="Register Page" element={<Register />} />
						<Route exact path="/404" name="Page 404" element={<Page404 />} />
						<Route exact path="/500" name="Page 500" element={<Page500 />} />
						{/* <PrivateRoute path="/" name="Home" component={DefaultLayout} /> */}
					</Routes>
				</Suspense>
			</BrowserRouter>
		)
	}
}

export default App
