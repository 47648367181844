export function AwsConfigAuth() {
	return {
		// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
		// identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

		// REQUIRED - Amazon Cognito Region
		region: process.env.REACT_APP_AWS_REGION,

		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: process.env.REACT_APP_USERPOOL_ID,

		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID,

		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		mandatorySignIn: true,

		// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
		// authenticationFlowType: 'USER_PASSWORD_AUTH',
		// authenticationFlowType: 'USER_SRP_AUTH',

		// OPTIONAL - Hosted UI configuration
		oauth: {
			domain: 'pgcsnow-pixida-login.auth.us-east-1.amazoncognito.com',
			scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
			redirectSignIn: 'http://localhost:3000/',
			redirectSignOut: 'http://localhost:3000/login',
			responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
		},
	}
}
